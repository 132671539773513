<template>
  <div>
    <br />
    <div class="form-container">
      <div v-for="(data, idx) in formData" >
        <hr v-if="idx!=0">
        <br />
        <h3>New Shop #{{idx+1}}</h3>
        <label>Name</label><br />
        <b-form-input
          placeholder=".."
          v-model="data.name"
        ></b-form-input
        ><br /><br />

        <label>Url Toko</label><br />
        <b-input-group prepend="https://bislin.com/@">
          <b-form-input
            placeholder=".."
            v-model="data.link"
          ></b-form-input>
        </b-input-group>
        <br /><br />

        <label>Email</label><br />
        <b-form-input
          placeholder=".."
          v-model="data.email"
          type="email"
        ></b-form-input>
        <br /><br />
        
        <label>Password Awal</label><br />
        <b-form-input
          placeholder=".."
          v-model="data.password"
        ></b-form-input
        ><br /><br />

        <label>No WA</label><br />
        <b-input-group prepend="+62">
          <b-form-input
            placeholder=".."
            v-model="data.wa"
            type="number"
          ></b-form-input>
        </b-input-group><br /><br />
      </div>

      <my-button type="blue" size="sm" block class="mt-4-5" rounded @click="addShop">
        Tambah Toko
      </my-button>

      <my-button type="blue" size="lg" block class="mt-4-5" rounded v-if="!showLoading" @click="save">
        SIMPAN & LANJUTKAN
      </my-button>
      <my-button type="blue" size="lg" block class="mt-4-5" rounded disabled v-else>
        <b-spinner small></b-spinner>
        <span class="sr-only">Loading...</span>
      </my-button>

    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {  
      showLoading: false,
      formData: [{
        name: '',
        email: '',
        link: '',
        password: '',
        wa: null
      }],
      formResponse: []
    };
  },

  methods: {
    ...mapActions({
      createShop: types.CREATE_SHOP
    }),
    addShop() {
      this.formData.push({
        name: '',
        email: '',
        link: '',
        password: '',
        wa: null
      })
    },
    save() {
      this.showLoading = true;
      this.formResponse = [];
      var finish = 0;
      for (var i = 0; i < this.formData.length; i++) {
        if(this.formData[i].name && this.formData[i].link && this.formData[i].email && this.formData[i].password) {
          this.createShop(this.formData[i])
          .then(response => {
            this.toastSuccess('b-toaster-top-center', "Success add new shop "+response[0]);
            finish++;
            if(finish == this.formData.length) {
              this.finishHandle();
            }
          })
          .catch(error => {
            this.formResponse.push(error.data);
            this.toastError('b-toaster-top-center', error.message);
            finish++;
            if(finish == this.formData.length) {
              this.finishHandle();
            }
          });
        } else if(this.formData[i].name || this.formData[i].link || this.formData[i].email || this.formData[i].password || this.formData[i].wa) {
          this.formResponse.push(this.formData[i]);
          finish++;
          if(finish == this.formData.length) {
            this.finishHandle();
          }
        } else {
          finish++;
          if(finish == this.formData.length) {
            this.finishHandle();
          }
        }
      }
    },
    finishHandle() {
      this.formData = [];
      if(this.formResponse.length > 0) {
        for (var i = 0; i < this.formResponse.length; i++) {
          this.formData.push(this.formResponse[i]);
        }
        this.toastWarning('b-toaster-top-center');
      } else {
        this.formData = [{
          name: '',
          email: '',
          link: '',
          password: '',
          wa: null
        }]
      }
      this.showLoading = false;
      this.toastError('b-toaster-top-center', error.message);
    },
    toastSuccess(toaster, message) {
      this.$bvToast.toast(message, {
        title: 'Notification',
        toaster: toaster,
        solid: true,
        variant: 'success',
        autoHideDelay: 3000
      })
    },
    toastError(toaster, error, append = false, ) {
      this.$bvToast.toast(error, {
        title: 'Error',
        toaster: toaster,
        solid: true,
        variant: 'danger',
        autoHideDelay: 3000
      })
    },
    toastWarning(toaster) {
      this.$bvToast.toast("Data yang masih tertinggal di form belum tersimpan", {
        title: 'Warning!',
        toaster: toaster,
        solid: true,
        variant: 'warning',
        autoHideDelay: 3000
      })
    }
  },

  mounted() {
    
  }
};
</script>

<style>

.button-ya {
  border: 1px solid #3bbaed;
  color: #3bbaed;
  margin-right: 11px;
  background: #fff;
  min-width: 22px;
  margin-top: 11px;
}

@media (min-width: 576px) {
  #modal-icon .modal-lg {
    max-width: 544px;
  }
 
}
</style>
